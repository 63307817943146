import "./App.css";
import { Center, Text, VStack, Image, Box } from "@chakra-ui/react";
import Logo from "./img/DragonMindAI.png"

export default function FrontBody() {
  return (
    <>
      <video playsInline autoPlay muted loop>
        <source src="/videos/dragVid6.mp4" type="video/mp4" />
        Your browser does not support the video tag. I suggest you upgrade your
        browser.
      </video>

      <Center className="overlay" paddingTop={["17%", "35%"]}>
        <VStack spacing={6}>
          <Box className="logo-wrapper">
            <Image
              src={Logo}
              alt="DragonMind AI Logo"
              width={["150px", "200px"]}
              height="auto"
              objectFit="contain"
              zIndex={2}
              opacity="0.95"
              className="glowing-logo"
            />
          </Box>
          <Text
            as="h2"
            color={"white"}
            fontSize={["sm","xl"]}
            mb="1rem"
            zIndex={2}
            fontWeight="bold"
            padding={2}
          >
            CA:{" "}
            <Text
              display={"inline-block"}
              bgGradient="linear(to-r, green.200, cyan.400)"
              bgClip="text"
              wordBreak="break-all"
            >
              EsgZKb1hgRGJ6ShRqdbWYAu25yeLTxK2Abmi7uKN5nFL
            </Text>
          </Text>
        </VStack>
      </Center>
    </>
  );
}
