import { Box } from "@chakra-ui/react";
import "../App.css";
import Footer from "../Footer";
import AppHeader from "./appHeader";
import Body from "./Body";
import UnderConstruction from "./underConstruction";


export default function ChatBot() {
    return (
      <Box bg="gray.900">
        <AppHeader />
        <UnderConstruction />
        <Footer />
      </Box>
    );
}
