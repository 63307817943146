import "./App.css";
import {
    Box,
    Center,
    Divider,
    Text,
    Icon,
} from "@chakra-ui/react";
import { FaGithub, FaTwitter, } from "react-icons/fa";

export default function Footer() {
    return (
        <Box bg="gray.900" color="white">
            <Center p={"0.1rem"}pt=".5rem">
                <a href="https://twitter.com/dragonmindai">
                    <Icon boxSize={"2rem"} mr="1rem" as={FaTwitter} />
                </a>
                <a href="https://github.com">
                    <Icon boxSize={"2rem"} mr="1rem" as={FaGithub} />
                </a>
            </Center>

            <Center>
                <Divider width={"80%"} />
            </Center>
            <Text textAlign="center" p=".5rem">
                ©{new Date().getFullYear()} DragonMind AI All rights reserved.
            </Text>
        </Box>
    );
}
