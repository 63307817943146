import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import ChatBot from "./ChatBot/ChatBot";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const theme = extendTheme({
    shadows: {
        white: "0 25px 50px -12px rgba(255, 255, 255, 0.8)",
      whitelg: 'rgba(255, 255, 255, 0.15) 0px 0px 0px 1px, rgba(255, 255, 255, 0.2) 0px 5px 10px, rgba(255, 255, 255, 0.1) 0px 15px 40px',
      greenlg: 'rgba(59, 250, 154, 0.55) 0px 0px 0px 1px, rgba(59, 250, 154, 0.45) 0px 5px 10px, rgba(59, 250, 154, 0.65) 0px 15px 40px',
      bluelg: 'rgba(75, 248, 255, 0.55) 0px 0px 0px 1px, rgba(75, 248, 255, 0.35) 0px 5px 10px, rgba(75, 248, 255, 0.65) 0px 15px 40px',
      darkgreenlg: 'rgba(0, 50, 72, 0.34) 0px 0px 0px 1px, rgba(0, 100, 72, 0.94) 0px 5px 10px, rgba(0, 100, 72, 0.94) 0px 10px 20px',
    }
  })

root.render(
    <ChakraProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<App />} />
                    <Route path="ChatBot" element={<ChatBot />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
