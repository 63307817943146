import { Box } from "@chakra-ui/react";
import "./App.css";
import FrontBody2 from "./FrontBody2";

export default function App() {
    return (
      <Box className="app" >
        <FrontBody2 />
      </Box>
    );
}
