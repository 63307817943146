import "../App.css";
import {
    Box,
    Center,
    Flex,
    Icon,
    Spacer,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ImFire } from "react-icons/im";

var BurnValue = 500;

export default function AppHeader() {
    return (
        <Flex p={4} zIndex={"1"} bgGradient="linear(gray.900 90%, green.300)">
            <Box>
                <Link to="/">
                    <Text
                        as="h2"
                        color={"white"}
                        fontSize="2xl"
                        bgGradient="linear(to-r, green.200, cyan.400)"
                        bgClip="text"
                        fontWeight="extrabold"
                        mt="-1.5"
                    >
                        DragonMind AI
                    </Text>
                </Link>
            </Box>
            <Spacer />
            <Tooltip
                label="Amount of DMAI token burned per question asked."
                fontSize="md"
                hasArrow
            >
                <Box float="right" display="block" mt="-2.5">
                    <Text
                        textColor="white"
                        textAlign={"center"}
                        fontWeight="extrabold"
                        bgGradient="linear(to-b, red, orange)"
                        bgClip="text"
                    >
                        {BurnValue}
                    </Text>
                    <Center>
                        <Icon as={ImFire} boxSize={5} color="red.500" />
                    </Center>
                </Box>
            </Tooltip>
        </Flex>
    );
}
