import "../App.css";
import { Box, Center, Heading, Text, Icon, Progress } from "@chakra-ui/react";
import { MdConstruction } from "react-icons/md";
import { FaHardHat } from "react-icons/fa";

var progressComplete = 40;

export default function UnderConstruction() {
    return (
        <Box height={"100vh"} bg="gray.800">
            <Center>
                <Icon
                    as={MdConstruction}
                    color="white"
                    boxSize={"5rem"}
                    p="1rem"
                />
                <Heading fontSize="4xl" color={"white"}>
                    Under Construction
                </Heading>
                <Icon as={FaHardHat} color="white" boxSize={"5rem"} p="1rem" />
            </Center>

            <Center>
                <Text
                    fontSize="lg"
                    p="1rem"
                    color="gray.100"
                    textAlign={"center"}
                    fontWeight="extrabold"
                    bgGradient="linear(to-l, green.100, white)"
                    bgClip="text"
                >
                    DMAI ChatBot v1.0 is under rapid development. Once finished
                    Open Source GitHub Repository will be made available.
                </Text>
            </Center>

            <Heading
                fontSize="2xl"
                color={"white"}
                mt="3rem"
                textAlign={"center"}
            >
                Amount of Progress Complete - {progressComplete}%
            </Heading>
            <Center>
                <Progress
                    hasStripe
                    value={progressComplete}
                    w="50%"
                    size={"lg"}
                    isAnimated
                    colorScheme={"green"}
                />
            </Center>
        </Box>
    );
}
